import React from 'react';
import {ReactComponent as TopCurtain} from '../assets/ust.svg';
import {ReactComponent as LeftCurtain} from '../assets/sol.svg';
import {ReactComponent as RightCurtain} from '../assets/sag.svg';
import { useBreakpoints, useCurrentWidth  } from 'react-breakpoints-hook';

export default function Viewer(props){

    let { xs, sm, md, lg } = useBreakpoints({
        xs: {min: 0, max: 360},
        sm: {min: 361, max: 960},
        md: {min: 961, max: 1300},
        lg: {min: 1301, max: null},
      });
      let width = useCurrentWidth();

    return(
        <>
        <div style={{
            width:'100vw',
            height:'100vh',
            background: 'rgb(229,230,229)',
            background: 'radial-gradient(circle, rgba(229,230,229,1) 10%, rgba(75,10,10,1) 100%)',
            overflowX: 'hidden',
            overflowY: 'hidden',
            //border: "20px solid transparent",
            //borderImage:`url(${tile}) 100 round`,

            /*border:'5px solid black',*/
        }}        >

            
            {(lg) && 
                <div style={{height : "100%", position : "absolute", top : 0, left : 0}}>
                    <LeftCurtain height="100%" width="auto" viewBox="0 0 437 2735"/>
                </div>
            }
            {(lg) &&  
                <div style={{height : "100%", position : "absolute", top : 0, right : 0}}>
                    <RightCurtain height="100%" width="auto" viewBox="5120 0 437 2735"/>
                </div>
            }
            <div style={{width : "100%", position : "absolute", top : 0, left : 0}}>
                <TopCurtain width="100%" height="auto" viewBox={(lg) ? "0 0 5551 351" : "0 0 2000 351"}/>
            </div>
            {/* <div style={{width : "100%", position : "absolute", bottom : 0, left : 0}}><BottomCurtain width="100%" height="auto" viewBox="0 2180 5551 1220"/></div> */}
            <nviewer-widget sku={props.sku} type={props.type}>
            </nviewer-widget>
            
        </div>
        {/* <div style={{
                        backgroundImage: `url(${curtain})`,
                        backgroundSize: "cover",
                        width:'100%',
                        height:'100%',
                        position:'absolute',
                    }}
        >
        </div> */}
        </>
    );
}