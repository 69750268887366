import './App.css';
import Single from './pages/Single';
import { Route, Switch } from 'react-router-dom';

function App() {

  // TODO : Tekli model göstermek için bir sayfa yapılacak.
  // TODO : Çoklu model göstermek için bir sayfa yapılacak.
  return (
    <div className="App">
      <Switch>
          <Route exact path="/live/:data" component={Single} />
      </Switch>
    </div>
  );
}

export default App;
