import React,{useState, useEffect,useRef} from "react";
import Viewer from "../components/viewer";

export default function Single(){


    const [sku, setSku] = useState('');

    useEffect(()=>{
        let exploded = window.location.toString().split('/')
        let skuData = exploded[exploded.length-1]
        setSku(skuData);
         
    },[])



    return(
        <div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
                overflowX: 'hidden',
                overflowY: 'hidden',
                //backgroundColor: "#966F33",
                //backgroundImage: `url(${curtain})`,
                //backgroundSize: "contain",
                //backgroundRepeat: "no-repeat",
            }}>
            {sku && <Viewer sku={sku} type="single"></Viewer>}
            </div>
            {/* <button className="sheetButton"  onClick={()=>setSheetOpen(true)} style={{width:'100px',height:'45px'}}> Detail</button> */}
        </div>
    );
}